import React from 'react';
import './Login.css';

function Login() {
    return (
        <div className="login-container">
            <div className="login-box">
                <h2 className="login-title">Login</h2>
                <form>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Username" />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                        />
                    </div>
                    <div className="form-options">
                        <div>
                            <input type="checkbox" />
                            <label>Lembre de mim</label>
                        </div>
                    </div>
                    <button type="submit" className="btn">
                        Login
                    </button>
                </form>
                <p className="register-text">
                    <button type="submit" className="btn">
                        Cadastrar-se
                    </button>
                </p>
                <a href="/" className="esqueceu-asenha">esqueci minha senha?</a>
            </div>
        </div>
    );
}

export default Login;
